import { Record } from "../../../fable_modules/fable-library-js.4.16.0/Types.js";
import { record_type, array_type, string_type, option_type, lambda_type, class_type, unit_type } from "../../../fable_modules/fable-library-js.4.16.0/Reflection.js";
import { INewItemRequestApi_$reflection } from "../../../fable_modules/pogona_vendor_api_lib.fable.1.394.0/INewItemRequestApi.fs.js";
import { ClientAuthenticatedAdalApiClient } from "@psp/pogona_adal_js";
import { ReasonForNewItem_$reflection, NewItemRequest_$reflection } from "../../../fable_modules/pogona_vendor_domain.fable.1.105.0/NewItemRequest.fs.js";
import { ProcessingProfile_$reflection, ProcessFileStatus_$reflection, UnitOfMeasure_$reflection, PspEmployee_$reflection, DistributionCenter_$reflection, Vendor_$reflection, Brand_$reflection, Manufacturer_$reflection } from "../../../models/Lookups.fs.js";
import { BatchSearchResult_$reflection, NewItemSearchResult_$reflection } from "../../../models/SearchNewItemRequest.fs.js";
import { CHProductSearchItem_$reflection } from "../../../fable_modules/pogona_vendor_api_lib.fable.1.394.0/ContentHubTypes.fs.js";
import { ItemUpdateFileSearchResult_$reflection } from "../../../models/ItemUpdateFile.fs.js";

export class VendorPortalState extends Record {
    constructor(getClient, authClient, test, newItemRequest, manufacturers, brands, vendors, distributionCenters, reasonsForNewItem, categoryManagers, inventoryManagers, unitOfMeasures, newRequestItemSearchResult, batchSearchResult, uploadedFiles, hierarchyLookup, contentHubProduct, contentHubProductRetailMeasure, itemUpdateFileSearchResult, processFileStatuses, processingProfiles) {
        super();
        this.getClient = getClient;
        this.authClient = authClient;
        this.test = test;
        this.newItemRequest = newItemRequest;
        this.manufacturers = manufacturers;
        this.brands = brands;
        this.vendors = vendors;
        this.distributionCenters = distributionCenters;
        this.reasonsForNewItem = reasonsForNewItem;
        this.categoryManagers = categoryManagers;
        this.inventoryManagers = inventoryManagers;
        this.unitOfMeasures = unitOfMeasures;
        this.newRequestItemSearchResult = newRequestItemSearchResult;
        this.batchSearchResult = batchSearchResult;
        this.uploadedFiles = uploadedFiles;
        this.hierarchyLookup = hierarchyLookup;
        this.contentHubProduct = contentHubProduct;
        this.contentHubProductRetailMeasure = contentHubProductRetailMeasure;
        this.itemUpdateFileSearchResult = itemUpdateFileSearchResult;
        this.processFileStatuses = processFileStatuses;
        this.processingProfiles = processingProfiles;
    }
}

export function VendorPortalState_$reflection() {
    return record_type("VendorPortalState.VendorPortalState", [], VendorPortalState, () => [["getClient", option_type(lambda_type(unit_type, class_type("Fable.Core.JS.Promise`1", [INewItemRequestApi_$reflection()])))], ["authClient", option_type(class_type("JsImports.ClientAuthenticatedAdalApiClient", void 0, ClientAuthenticatedAdalApiClient))], ["test", string_type], ["newItemRequest", option_type(NewItemRequest_$reflection())], ["manufacturers", option_type(array_type(Manufacturer_$reflection()))], ["brands", option_type(array_type(Brand_$reflection()))], ["vendors", option_type(array_type(Vendor_$reflection()))], ["distributionCenters", option_type(array_type(DistributionCenter_$reflection()))], ["reasonsForNewItem", option_type(array_type(ReasonForNewItem_$reflection()))], ["categoryManagers", option_type(array_type(PspEmployee_$reflection()))], ["inventoryManagers", option_type(array_type(PspEmployee_$reflection()))], ["unitOfMeasures", option_type(array_type(UnitOfMeasure_$reflection()))], ["newRequestItemSearchResult", option_type(NewItemSearchResult_$reflection())], ["batchSearchResult", option_type(BatchSearchResult_$reflection())], ["uploadedFiles", option_type(array_type(string_type))], ["hierarchyLookup", array_type(string_type)], ["contentHubProduct", option_type(CHProductSearchItem_$reflection())], ["contentHubProductRetailMeasure", option_type(string_type)], ["itemUpdateFileSearchResult", option_type(ItemUpdateFileSearchResult_$reflection())], ["processFileStatuses", option_type(array_type(ProcessFileStatus_$reflection()))], ["processingProfiles", option_type(array_type(ProcessingProfile_$reflection()))]]);
}

export function getClient(state) {
    const matchValue = state.getClient;
    if (matchValue == null) {
        throw new Error("getClient function not set");
    }
    else {
        return matchValue();
    }
}

export function getAdalClient(state) {
    const matchValue = state.authClient;
    if (matchValue == null) {
        throw new Error("adalClient not set");
    }
    else {
        return matchValue;
    }
}

export function initVendorPortalState() {
    return new VendorPortalState(void 0, void 0, "start", void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, [], void 0, void 0, void 0, void 0, void 0);
}

export default initVendorPortalState();

