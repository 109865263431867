export function getDateString(date: Date): string {
  // Get year, month, and date
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1; // Months are 0-based in JavaScript
  const day: number = date.getDate();

  // Pad single-digit months and days with a leading zero
  const pad = (value: number): string => (value < 10 ? '0' + value : value.toString());

  return `${year}-${pad(month)}-${pad(day)}`;
}

// Get time string in format yyyyMMddHHmmss
export function getDateTimeString(): string {
  const now = new Date();
  const dateString = now.toISOString().slice(0, 19).replace(/[-:]/g, '').replace('T', '');
  return dateString;
}

export function getFilenameFromContentDisposition(contentDisposition: string): string | null {
  if (!contentDisposition) {
    return null;
  }
  const filenameToken = `filename=`;
  let filename = contentDisposition.split(';').find(n => n.includes(filenameToken));
  if (!filename) {
    return null;
  }
  filename = filename.replace(filenameToken, '').trim();
  // Remove quotes if present
  if (filename.startsWith(`"`) && filename.endsWith(`"`)) {
    filename = filename.slice(1, -1);
  }
  return filename;
}
