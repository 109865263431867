<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="poNumber"
        ref="poNumberTextField"
        label="PO Number"
        clearable
        @keydown.enter="navigate"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field v-model="poName" label="Pet Partner Name" clearable @keydown.enter="navigate" />
    </v-col>
    <v-col>
      <v-text-field v-model="siteId" label="Site ID" clearable @keydown.enter="navigate" />
    </v-col>
    <v-col>
      <v-menu
        v-model="fromDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="fromDateFormatted"
            label="From Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="fromDate" no-title @input="fromDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="toDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="toDateFormatted"
            label="To Date"
            readonly
            v-on="on"
            persistent-hint
            clearable
          ></v-text-field>
        </template>
        <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-btn @click="navigate">Search</v-btn>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';

defineComponent({
  name: 'POSearch',
});

const emit = defineEmits(['search']);
const poNumber = ref<string | null>('');
const router = useRouter();
const poNumberTextField = ref<HTMLInputElement | null>(null);
const poName = ref<string | null>('');
const siteId = ref<string | null>('');
const fromDateMenu = ref(false);
const fromDate = ref<Date | null>(null);
const fromDateFormatted = ref('');
const toDateMenu = ref(false);
const toDate = ref<Date | null>(null);
const toDateFormatted = ref('');

const emitSearch = () => {
  emit('search');
};

// Navigate
function navigate() {
  const newRouteParams = {
    poSearch: poNumber.value ? encodeURIComponent(poNumber.value) : '-',
    poName: poName.value ? encodeURIComponent(poName.value) : '-',
    siteId: siteId.value ? encodeURIComponent(siteId.value) : '-',
    fromDate: fromDateFormatted.value ? encodeURIComponent(fromDateFormatted.value) : '-',
    toDate: toDateFormatted.value ? encodeURIComponent(toDateFormatted.value) : '-',
  };
  if (
    router.currentRoute.name !== 'vendorportal_posearchhome' ||
    JSON.stringify(router.currentRoute.params) !== JSON.stringify(newRouteParams)
  ) {
    router.push({
      name: 'vendorportal_posearchhome',
      params: newRouteParams,
    });
  } else {
    emitSearch();
  }
}

const searchFromRoute = computed(() => {
  if (router.currentRoute.params.poSearch) {
    return router.currentRoute.params.poSearch === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.poSearch);
  } else {
    return '';
  }
});
const poNameFromRoute = computed(() => {
  if (router.currentRoute.params.poName) {
    return router.currentRoute.params.poName === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.poName);
  } else {
    return '';
  }
});
const siteIdFromRoute = computed(() => {
  if (router.currentRoute.params.siteId) {
    return router.currentRoute.params.siteId === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.siteId);
  } else {
    return '';
  }
});
const routeFromDate = computed(() => {
  if (router.currentRoute.params.fromDate) {
    return router.currentRoute.params.fromDate === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.fromDate);
  } else {
    return null;
  }
});
const routerToDate = computed(() => {
  if (router.currentRoute.params.toDate) {
    return router.currentRoute.params.toDate === '-'
      ? ''
      : decodeURIComponent(router.currentRoute.params.toDate);
  } else {
    return null;
  }
});
function formatDate(date) {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}
watch(fromDate, newVal => {
  fromDateFormatted.value = formatDate(newVal) ?? '';
});
watch(toDate, newVal => {
  toDateFormatted.value = formatDate(newVal) ?? '';
});

onMounted(() => {
  poNumberTextField.value?.focus();
  if (router.currentRoute.name === 'vendorportal_posearchhome') {
    poNumber.value = searchFromRoute.value;
    poName.value = poNameFromRoute.value;
    siteId.value = siteIdFromRoute.value;
    fromDateFormatted.value = routeFromDate.value ?? '';
    toDateFormatted.value = routerToDate.value ?? '';
  }
});
</script>
